import "./styles/frontpage.css";
import "./styles/infoCards.css";
import '@glidejs/glide/dist/css/glide.core.min.css';
import '@glidejs/glide/dist/css/glide.theme.min.css';
import Glide from '@glidejs/glide'
window.Glide = Glide;

import runFrontPageMap from './modules/frontPageMap';

runFrontPageMap();

document.addEventListener("DOMContentLoaded", e => {
    document.querySelector(".footer__content__menu__small__up-btn").addEventListener("click", e => {
        e.preventDefault();
        document.querySelector(".header").scrollIntoView();
    });
});