import ymaps from 'ymaps';

export default () => {
    document.addEventListener("DOMContentLoaded", () => {

        const startingPoint = window.innerWidth < 400 ? [54.899430223339735, 52.29555991157596] :
            window.innerWidth < 577 ? [54.899396337901095, 52.29921199076834] :
                window.innerWidth < 991 ? [54.89961296539255,52.29893407702348] : [54.89979909697881, 52.30277170609769];

        ymaps
            .load('//api-maps.yandex.ru/2.1/?lang=ru_RU')
            .then(maps => {
                const map = new maps.Map('map', {
                    center: startingPoint,
                    zoom: 16,
                    controls: ['zoomControl'],
                    behaviors: ['drag', 'multiTouch']
                });

                const labelTextHolder = document.getElementById('humanReadableAddress');
                if (!labelTextHolder) {
                    throw new Error("Human readable address source not found!");
                }

                var myPlacemark = new maps.Placemark([54.899430223339735, 52.29555991157596], {
                    iconCaption: labelTextHolder.value
                }, {
                    preset: 'islands#redIcon'
                });

                map.geoObjects.add(myPlacemark);
            })
            .catch(error => console.log('Failed to load Yandex Maps', error));
    });
}